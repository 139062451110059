<template>
    <v-row>
        <v-col cols="12">
            <v-row>
                <v-col md="7">
					<h3>PONCHES</h3>
					<span>{{ filtered_timestamps.length }} en total</span>	
				</v-col>
                <v-col md="5">
                    <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Buscar" class="rounded-lg" outlined dense single-line hide-details></v-text-field>
                </v-col>
            </v-row>
			<v-row>
                <v-col md="3" class="d-flex align-start">
                    <v-text-field v-model="date" @input="get_timestamps()" type="date" label="Fecha" hint="mm/dd/yyyy" prepend-inner-icon="mdi-calendar" class="rounded-lg" outlined dense required persistent-hint></v-text-field>
                </v-col>
				<v-col v-if="is_admin" md="3" class="d-flex align-start">
					<v-select v-model="area" :items="areas" label="Area" @change="filter_change" class="rounded-lg" outlined dense hide-details></v-select>
				</v-col>
				<v-col class="d-flex align-start justify-end">
					<download-csv :name="generate_file_name(date, 'csv')" :data="filtered_timestamps" :labels="labels" :fields="fields">
						<v-btn depressed>Exportar a excel<v-icon class="ml-2" small>mdi-download</v-icon></v-btn>
					</download-csv>
				</v-col>
			</v-row>
        </v-col>
        <v-col cols="12">
            <v-data-table :headers="headers" :items="filtered_timestamps" :search="search" dense hide-default-footer :loading="loading" :items-per-page="-1">
                <template v-slot:[`item.date`]="{ item }">
					{{ format_date(item.date) }}
                </template>
				<template v-slot:[`item.actions`]="{ item }">
                    <v-btn icon @click="$root.$emit('timestamp-details', { timestamp: item })"><v-icon small>mdi-pencil</v-icon></v-btn>
                    <v-btn icon @click="delete_timestamp(item.id)"><v-icon small>mdi-delete</v-icon></v-btn>
                </template>
            </v-data-table>

            <timestamp-details></timestamp-details>
        </v-col>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import { format_date } from '@/assets/modules'

import TimestampDetails from '@/components/TimestampDetails'

export default {
	name: 'Timestamps',
    components: { TimestampDetails },
	data: () => ({
        search:  '',
		area: localStorage.getItem('ponches-area') || 'Todas',
        date: localStorage.getItem('ponches-date') || (new Date()).toISOString().substr(0, 10),
        timestamps: [],
		areas: ['Todas', 'Amelia', 'Ramon', 'Rufina'],
		fields: ['name', 'employee_number', 'area', 'date', 'time'],
		labels: { name: 'PERSONNAME', employee_number: 'PERSONNUM', area: 'AREA', date: 'DATE', time: 'TIME' },
		headers: [
			{ text: 'Nombre', align: 'start', sortable: true, value: 'name', },
			{ text: 'No. Empleado', align: 'start', sortable: true, value: 'employee_number', },
			{ text: 'Id tarjeta', align: 'start', sortable: true, value: 'card_id', },
			{ text: 'Area', value: 'area', },
			{ text: 'Fecha', value: 'date', },
			{ text: 'Hora', value: 'time', },
            { text: '', value: 'actions', align: 'end' }
		],

		loading: false,
	}),
	mounted() {
		this.get_timestamps()
        this.$root.$on('refresh-timestamps', () => this.get_timestamps())
	},
	methods: {
		format_date,
		filter_change() { localStorage.setItem('ponches-area', this.area) },
		get_timestamps() {
			this.loading = true
			this.$http.get(`/admin/timestamps/${this.date}`).then((resp) => {
				this.timestamps = resp.data.timestamps
                localStorage.setItem('ponches-date', this.date)
			}).finally(() => {
				this.loading = false
			})
		},
		delete_timestamp(id) {
			this.loading = true
			this.$http.delete(`/admin/timestamps/${id}`).then(() => {
                const i = this.timestamps.findIndex((t) => t.id === id)
                if (i > -1) this.timestamps.splice(i, 1)
			}).finally(() => {
				this.loading = false
			})
		},
        /** 
		 * 	generate file name
		 * 	@param 	{Object} extension 	- file extension (csv, pdf)
		 * 	@return {String} 			- file name
		 */ 
        generate_file_name: (date, extension) => `timestamps-${date}.${extension}`,
	},
	computed : {
		...mapGetters(['username', 'employees', 'is_logged_in', 'is_admin']),
		filtered_timestamps() {
			return (this.is_admin && this.area !== 'Todas')
			? this.timestamps.filter((t) => t.area === this.area)
			: this.timestamps
		}
    }
};
</script>
  