<template>
    <v-dialog v-model="dialog" persistent max-width="500px">
        <v-form ref="form" @submit.prevent="submit">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Editar ponche</span>
                    <v-spacer></v-spacer>
                    <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle>Detalles de ponche</v-card-subtitle>
                <v-card-text>
                    <v-container fluid class="px-0">
                        <v-row>
                            <v-col cols="12" md="6" class="pb-0">
                                <v-text-field v-model="name" label="Nombre" disabled readonly class="rounded-lg" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pb-0">
                                <v-text-field v-model="employee_number" label="No. de empleado" disabled readonly class="rounded-lg" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pb-0">
                                <v-text-field v-model="area" label="Area" disabled readonly class="rounded-lg" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pb-0">
                                <v-text-field v-model="card_id" label="Id tarjeta" disabled readonly class="rounded-lg" outlined dense></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" class="pb-0">
                                <v-text-field v-model="date" type="date" label="Fecha" :rules="rules" hint="mm/dd/yyyy" prepend-inner-icon="mdi-calendar" class="mb-3 rounded-lg" outlined dense required persistent-hint></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pb-0">
                                <v-text-field v-model="time" type="time" label="Hora" :rules="rules" prepend-inner-icon="mdi-clock-time-four-outline" class="rounded-lg" outlined dense required></v-text-field>
                            </v-col>
                            </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cerrar</v-btn>
                    <v-btn type="submit" color="blue darken-1" text>Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
import { time_to_12hr, time_to_24hr } from '@/assets/modules'

export default {
	name: 'TimestampDetails',
	data: () => ({
		dialog: false,
        create: false,
        loading: false,

        id: -1,
        date: (new Date()).toISOString().substr(0, 10),
        time: (new Date()).toLocaleTimeString('en-US', { hour12: false }),

        name: '',
        employee_number: '',
        area: '',
        current_employee_number: '',
        card_id: '',
        active: true,

        rules: [v => !!v || 'Campo es requerido'],
	}),
	mounted() {
        this.$root.$on('timestamp-details', ({ timestamp }) => {
            this.name = timestamp.name
            this.employee_number = timestamp.employee_number 
            this.area = timestamp.area
            this.card_id = timestamp.card_id
            this.id = timestamp.id
            this.date = timestamp.date
            this.time = this.time_to_24hr(timestamp.time)
            this.dialog = true
        })
	},
	methods: {
		time_to_12hr, time_to_24hr,
        close() {
            this.dialog = false
			this.$refs.form.reset()
        },
        submit() {
            if (!this.$refs.form.validate()) return
            this.loading = true

            this.$http.patch(`/admin/timestamps/`, this.timestamp).then(() => {
                this.$root.$emit('refresh-timestamps')
                this.close() 
            }).finally(() => {
                this.loading = false
            })
        }
	},
    computed: {
        timestamp() {
			return {
				id: this.id,
				date: this.date,
				time: this.time_to_12hr(this.time)
			}
        }
	},
};
</script>